"use client"

import { format } from "date-fns"
import Image from "next/image"
import Link from "next/link"
import { usePathname } from "next/navigation"
import { useEffect } from "react"

import styles from '../app/(front)/deals/lifetime/default.module.css'

function Footer({ defaultTheme }) {

    const pathname = usePathname();

    useEffect(() => {
        require("bootstrap/dist/js/bootstrap.js")
    }, [])

    return (
        <>
            <footer className="footer">
                <div className={`main-footer bg-footer-full-${defaultTheme} ${(usePathname().startsWith("/deals/") || usePathname().startsWith("/buy-vpn/") ? "d-none" : "")}`}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                <div className="ft-nav">
                                    <div className="ft-head">
                                        <span>PRODUCTS</span>
                                    </div>
                                    <ul className="list-unstyled">
                                        <li><Link href="/download/android-vpn/">VPN for Android</Link></li>
                                        <li><Link href="/download/ios-vpn/">VPN for iPhone</Link></li>
                                        <li><Link href="/download/windows-vpn/">VPN for Windows</Link></li>
                                        <li><Link href="/download/mac-vpn/">VPN for Mac</Link></li>
                                        <li><Link href="/download/linux-vpn/">VPN for Linux</Link></li>
                                        <li><Link href="/download/router-vpn/">VPN for Routers</Link></li>
                                        <li><Link href="/download/chrome-vpn/">VPN for Chrome</Link></li>
                                        <li><Link href="/download/firefox-vpn/">VPN for Firefox</Link></li>
                                        <li><Link href="/download/kodi-vpn/">VPN for Kodi</Link></li>
                                        <li><Link href="/travel-vpn/">VPN for Travel</Link></li>
                                        {/* <li><Link href="/download/vpn-protocols.php">Lightway VPN Protocol</Link></li> */}
                                        <li><Link href="/gaming-vpn/">VPN for Gaming</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                <div className="ft-nav">
                                    <div className="ft-head">
                                        <span>LEARN MORE</span>
                                    </div>
                                    <ul className="list-unstyled">
                                        <li><Link href="/what-is-a-vpn/">What is a VPN?</Link></li>
                                        {/* <li><Link href="/blog/">Blog</Link></li> */}
                                        <li><Link href="/streaming/">VPN for Streaming</Link></li>
                                        <li><Link href="/what-is-a-vpn/secure-vpn/">VPN for Security</Link></li>
                                        <li><Link href="/unblock-websites/">VPN for Unblocking Websites</Link></li>
                                        <li><Link href="/buy-vpn/">Plans & Pricing</Link></li>
                                        <li><Link href="/money-back-guarantee/">Money-Back Guarantee</Link></li>
                                    </ul>
                                </div>
                                <div className="ft-nav">
                                    <div className="ft-head">
                                        <span>TOOLS</span>
                                    </div>
                                    <ul className="list-unstyled">
                                        <li><Link href="/what-is-my-ip/">What is My IP?</Link></li>
                                        <li><Link href="/dns-leak-test/">DNS Leak Test</Link></li>
                                        <li><Link href="/ipv6-leak-test/">IPv6 Leak Test</Link></li>
                                        {/* <li><Link href="/webrtc-leak-test/">WebRTC Leak Test</Link></li>  */}
                                        <li><Link href="/password-generator/">Password Generator</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                <div className="ft-nav">
                                    <div className="ft-head">
                                        <span>FEATURES</span>
                                    </div>
                                    <ul className="list-unstyled">
                                        {/* <li><Link href="/buy-vpn/">Plans and Pricing</Link></li>
                                        <li><Link href="/what-is-a-vpn/anonymous-vpn/">Anonymous VPN</Link></li>
                                        <li><Link href="/what-is-a-vpn/secure-vpn/">Secure VPN</Link></li> */}
                                        <li><Link href="/features/">VPN Features</Link></li>
                                        <li><Link href="/servers/">VPN Servers</Link></li>
                                        {/* <li><Link href="/download/">Download Apps</Link></li> */}
                                    </ul>
                                </div>
                                <div className="ft-nav">
                                    <div className="ft-head">
                                        <span>PROGRAMS</span>
                                    </div>
                                    <ul className="list-unstyled">
                                        <li><Link href="/affiliates/">Affiliate Program</Link></li>
                                        <li><Link href="/youtube-creators/">YouTube Creators</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                <div className="ft-nav">
                                    <div className="ft-head">
                                        <span>ABOUT</span>
                                    </div>
                                    <ul className="list-unstyled">
                                        {/* <li><Link href="https://support.oystervpn.com/">About OysterVPN</Link></li> */}
                                        {/* <li><Link href="/money-back-guarantee/">Money-Back Guarantee</Link></li> */}
                                        <li><Link href="/terms-of-services/">Terms of Services</Link></li>
                                        <li><Link href="/privacy-policy/">Privacy Policy</Link></li>
                                        <li><Link href="/refund-policy/">Refund Policy</Link></li>
                                        <li><Link href="/affiliate-policy/">Affiliate Policy</Link></li>
                                    </ul>
                                </div>

                                <div className="ft-nav">
                                    <div className="ft-head">
                                        <span>Help</span>
                                    </div>
                                    <ul className="list-unstyled">
                                        <li><a href="https://support.oystervpn.com/">Support Center</a></li>
                                        <li><a href="https://support.oystervpn.com/faqs/">FAQs</a></li>
                                    </ul>
                                    <div className="ft-head">
                                        <span>Available on</span>
                                    </div>
                                    <ul className="list-unstyled d-flex">
                                        <li style={{ width: "50%" }} className="me-2">
                                            <a href="https://play.google.com/store/apps/details?id=com.oystervpn.app" target="_blank">
                                                <Image src="/assets/images/googleplay.png" width={308} height={90} alt="Google Play" />
                                            </a>
                                        </li>
                                        <li style={{ width: "50%" }}>
                                            <a href="https://apps.apple.com/ca/app/oystervpn-secure-and-fast-vpn/id1639558080" target="_blank">
                                                <Image src="/assets/images/applestore.png" width={308} height={90} alt="Apple Store" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`footerbar bg-dark-blue footer-copyright footer-copyright-${defaultTheme} ${usePathname() === '/buy-vpn/' ? 'footer-bg-' + defaultTheme : ''} ${usePathname().startsWith("/deals/lifetime") ? styles.halloweenFooter + " d-none" : ''} ${usePathname() === "/deals/black-friday/" ? styles.blackFridayFooter : ''}`}>
                    {/* <div className="inner-footer-img">
                <Image src="/assets/images/thanks-giving-pricing/hen-img.png" width={424} height={373} alt="" />
            </div> */}
                    <div className={`container ${usePathname().startsWith("/deals/lifetime") ? styles.footerTop : ''}`}>
                        <div className="row align-items-center">
                            {!usePathname().startsWith("/deals/lifetime") ?
                                <>
                                    <div className={["col-lg-6 col-md-6 col-sm-12 col-12 order-md-last",].join(" ")}>
                                        <div className="ft-social-links">
                                            <ul className="list-unstyled mb-0">
                                                <li><a href="https://www.facebook.com/oystervpn/" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="https://twitter.com/OysterVPN"><i className="fab fa-twitter" target="_blank"></i></a></li>
                                                <li><a href="https://www.linkedin.com/company/oystervpn/"><i className="fab fa-linkedin-in" target="_blank"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 order-md-first">
                                        <p className="copyright mb-0">&copy; {format(new Date(), 'YYY')} OysterVPN. All rights reserved.</p>
                                    </div>
                                </>
                                :
                                <div className="col-12">
                                    <p className="copyright mb-0 text-center">&copy; {format(new Date(), 'YYY')} OysterVPN. All rights reserved.</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </footer>
        </>

    )
}

export default Footer;